import * as React from "react";

import { injectIntl } from "gatsby-plugin-intl";
import { useState } from "react";
import ArrowLeft from "../../images/arrow_left.svg";
import parse from "html-react-parser";
import Calendar from "../../images/calendar_blue.svg";
import Cart from "../../images/cart.svg";
import "./ComplexServicePackagesDetail.css";
import { Link } from "gatsby";
import { moneyFormatFromNumber } from "../../utils/formatter";
import ReactMarkdown from "react-markdown";
import "../../pages/markdown.css";

// @ts-ignore
const ComplexServicePackagesDetail = ({ intl, analysisGroup }) => {
  const [toggleState, setToggleState] = useState(1);

  return (
    <div className="flex items-center justify-center bg-[#FAFAFA]">
      <div className="flex flex-col  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
        <div className="ml-[0.2rem] mt-[2rem] mb-[2rem] "></div>
        {/*HomeBack*/}
        <Link to="/complex-service-packages">
          <div className="flex mb-[2rem] items-center">
            <img
              src={ArrowLeft}
              alt=""
              className="sm:mb-[0rem] xs:mb-[1.7rem]"
            />
            <p className="font-semibold text-[1.25rem] ml-[10px] text-[#001439] cursor-pointer">
              {parse(
                intl.formatMessage({
                  id: `ServicePackages.ServicesDetail.HeaderBack`,
                })
              )}
            </p>
          </div>
        </Link>

        {/*Box*/}
        <div className="bg-[#EDF2FC] bg-opacity-25 flex sm:flex-row xs:flex-col sm:items-center xs:items-baseline lg:w-auto sm:w-auto sm:h-[11.875rem] xs:h-auto justify-between text-center  items-center mb-2 overflow-hidden rounded-xl sm:p-4 xs:p-[1rem] px-4 border border-[#EDF2FC] border-2  xs:text-[0.7rem] sm:text-sm lg:text-[0.8rem]">
          {/*Description*/}
          <div className="flex flex-col sm:w-[60%]  xs:w-auto ">
            <h4 className="font-semibold justify-around sm:text-[1.75rem] xs:text-[1.25rem] text-left leading-[2rem]">
              {analysisGroup.attributes.title}
            </h4>
            <div className="flex sm:flex-row xs:flex-col sm:mt-[3rem] xs:mt-[1.4rem]">
              <div className="flex sm:items-center justify-left">
                {/*<img src={Calendar} alt="" className="" />*/}
                {/*<p className="font-semibold text-[1rem] pl-[8px]">1</p>*/}
                {/*<p className="font-semibold text-[1rem] pl-[4px]">*/}
                {/*  {parse(*/}
                {/*    intl.formatMessage({*/}
                {/*      id: `ServiceMenu.AnalysisAndPrices.AllAnalysisTable.AllAnalysisTableRow.GeneralChemicalResearch.ClinicalBloodTest.term`,*/}
                {/*    })*/}
                {/*  )}*/}
                {/*</p>*/}
              </div>
              <div className="sm:ml-[2rem] sm:mt-[0rem] xs:mt-[1rem] flex sm:items-center justify-left">
                {/*<p className="sm:text-[1rem] xs:text-[0.9rem] font-medium">*/}
                {/*  {intl.formatMessage({*/}
                {/*    id: "ServiceMenu.AnalysisAndPrices.AllAnalysisTable.Material",*/}
                {/*  })}*/}
                {/*  :*/}
                {/*</p>*/}
                {/*<p className="sm:text-[1rem] xs:text-[0.9rem] font-bold pl-[4px]">*/}
                {/*  {parse(*/}
                {/*    intl.formatMessage({*/}
                {/*      id: `ServiceMenu.AnalysisAndPrices.AllAnalysisTable.AllAnalysisTableRow.GeneralChemicalResearch.ClinicalBloodTest.material`,*/}
                {/*    })*/}
                {/*  )}*/}
                {/*</p>*/}
              </div>
            </div>
          </div>
          {/*Price*/}
          <div className="mt-[1.4rem] flex justify-end sm:flex-col sm:grid-flow-dense xs:grid xs:grid-flow-col sm:gap-0 xs:gap-[4.4rem] sm:w-[20%] xs:w-auto text-right">
            <h2 className="font-semibold sm:text-[2rem] xs:text-[1.5rem] text-[#001439] opacity-75">
              {moneyFormatFromNumber(analysisGroup.attributes.price)}
              <span className="ml-[4px]">₸</span>
            </h2>

            {/*<button className="lg:mt-[2rem] sm:mt-[2.5rem]  flex items-center justify-center lg:w-[9rem] xs:w-[8rem] h-[2.8rem] bg-[#618EE4] rounded-[0.6rem]">*/}
            {/*  <img src={Cart} alt="" className="cart" />*/}
            {/*  <p className="font-semibold text-[1rem] text-white ml-[0.5rem]">*/}
            {/*    {parse(*/}
            {/*      intl.formatMessage({*/}
            {/*        id: `ServiceMenu.AnalysisAndPrices.Button`,*/}
            {/*      })*/}
            {/*    )}*/}
            {/*  </p>*/}
            {/*</button>*/}
          </div>
        </div>
        {/*Tab*/}
        <div className="h-[2.5rem] mt-[3rem] flex border border-b border-[#618EE4] border-opacity-50 border-t-0 border-x-0">
          <div
            className={
              toggleState === 1
                ? "services_detail_active  w-[8rem] flex justify-center items-center cursor-pointer"
                : " w-[8rem] flex justify-center items-center cursor-pointer"
            }
            onClick={() => setToggleState(1)}
          >
            <p className="sm:text-[1.125rem] text-[1rem]">
              {parse(
                intl.formatMessage({
                  id: `ServiceMenu.AnalysisAndPrices.AnalysisDetail.Tab.Description.Title`,
                })
              )}
            </p>
          </div>
          {/*<div*/}
          {/*    className={*/}
          {/*      toggleState === 2*/}
          {/*          ? "services_detail_active w-[15rem] flex justify-center items-center cursor-pointer"*/}
          {/*          : " w-[15rem] flex justify-center items-center cursor-pointer"*/}
          {/*    }*/}
          {/*    onClick={() => setToggleState(2)}*/}
          {/*>*/}
          {/*  {parse(*/}
          {/*      intl.formatMessage({*/}
          {/*        id: `ServiceMenu.AnalysisAndPrices.AnalysisDetail.Tab.Preparation.Title`,*/}
          {/*      })*/}
          {/*  )}*/}
          {/*</div>*/}
        </div>
        {/*Description*/}
        <div
          className={`flex flex-col mb-[10rem] ${
            toggleState === 1 ? "" : "hidden"
          }`}
        >
          <h4 className="font-semibold text-[1.25rem] mt-[2.5rem]">
            {parse(
              intl.formatMessage({
                id: `ServicePackages.ServicesDetail.TypesOfAnalysisTitle`,
              })
            )}
          </h4>
          <ul className="grid lg:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 font-regular text-[1rem] text-[#618EE4] mt-[1rem] gap-[0.75rem]">
            {analysisGroup.attributes.analyses.data.map((analysis) => (
              <li>{analysis.attributes.title}</li>
            ))}
          </ul>
          {analysisGroup.attributes.description ? (
            <div className="xl:w-full mb-10 mt-5 flex flex-col   px-2  markdown">
              <ReactMarkdown>
                {analysisGroup.attributes.description}
              </ReactMarkdown>
            </div>
          ) : null}
          {/*<h4 className="font-semibold text-[1.25rem] mt-[2.5rem]">*/}
          {/*  {parse(*/}
          {/*    intl.formatMessage({*/}
          {/*      id: `ServicePackages.ServicesDetail.BasicBloodBiochemistry.Description`,*/}
          {/*    })*/}
          {/*  )}*/}
          {/*</h4>*/}
          {/*<p className="font-medium text-[1rem] mt-[1rem]">*/}
          {/*  {parse(*/}
          {/*    intl.formatMessage({*/}
          {/*      id: `ServicePackages.ServicesDetail.BasicBloodBiochemistry.DescriptionText`,*/}
          {/*    })*/}
          {/*  )}*/}
          {/*</p>*/}
          {/*<div className="mt-[2.5rem] flex flex-col">*/}
          {/*  <h4 className="font-semibold text-[1.25rem]">*/}
          {/*    {parse(*/}
          {/*      intl.formatMessage({*/}
          {/*        id: `ServicePackages.ServicesDetail.BasicBloodBiochemistry.Indications`,*/}
          {/*      })*/}
          {/*    )}*/}
          {/*  </h4>*/}
          {/*  <ul className="list-disc list-inside mt-[1rem] font-medium text-[1rem]">*/}
          {/*    {parse(*/}
          {/*      intl.formatMessage({*/}
          {/*        id: `ServicePackages.ServicesDetail.BasicBloodBiochemistry.IndicationsText`,*/}
          {/*      })*/}
          {/*    )}*/}
          {/*  </ul>*/}
          {/*</div>*/}
          {/*<div className="mt-[2.5rem] flex flex-col">*/}
          {/*  <h4 className="font-semibold text-[1.25rem]">*/}
          {/*    {parse(*/}
          {/*      intl.formatMessage({*/}
          {/*        id: `ServiceMenu.AnalysisAndPrices.AnalysisDetail.Tab.Description.InterpretationOfResults`,*/}
          {/*      })*/}
          {/*    )}*/}
          {/*  </h4>*/}
          {/*  <p className="mt-[1rem] font-medium text-[1rem]">*/}
          {/*    {parse(*/}
          {/*      intl.formatMessage({*/}
          {/*        id: `ServiceMenu.AnalysisAndPrices.AnalysisDetail.Tab.Description.InterpretationOfResultsDesc`,*/}
          {/*      })*/}
          {/*    )}*/}
          {/*  </p>*/}
          {/*</div>*/}
        </div>
        {/*Preparation*/}
        <div
          className={`xl:w-full mb-10 mt-5 flex flex-col   px-2  markdown ${
            toggleState === 2 ? "" : "hidden"
          }`}
        >
          {analysisGroup.attributes.instruction ? (
            <ReactMarkdown>
              {analysisGroup.attributes.instruction}
            </ReactMarkdown>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ComplexServicePackagesDetail);
