import * as React from "react";
import Layout from "../components/Layout/Layout";
import ComplexServicePackagesDetail from "../components/complexServicePackages/ComplexServicePackagesDetail";
import { useQuery } from "@apollo/client";
import { GET_ANALYSIS_GROUP_BY_ID } from "../query/getAnalysis";
import { injectIntl } from "gatsby-plugin-intl";

const complexServicePackagesDetail = ({ intl, location }) => {
  // the parameter to return for URL. Example:
  // https://localhost:8000/?parameter1=firstParam&parameter2=secondParam
  const params = new URLSearchParams(location.search);
  const id = params.get("id");

  const { data, loading, error } = useQuery(GET_ANALYSIS_GROUP_BY_ID, {
    variables: {
      locale: intl.locale,
      id,
    },
  });

  if (loading) return "";

  if (error) return `Error! ${error.message}`;

  return (
    <Layout>
      <ComplexServicePackagesDetail analysisGroup={data.analysisGroup.data} />
    </Layout>
  );
};

export default injectIntl(complexServicePackagesDetail);
